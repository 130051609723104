<!-- eslint-disable no-restricted-syntax -->
<template>
  <div>
    <b-row>

      <!-- Upload arquivos -->

      <b-col cols="12">
        <b-input-group>
          <b-overlay
            :show="show"
            spinner-variant="primary"
            spinner-type="border"
            spinner-large
            rounded="lg"
          >
            <b-form-file
              ref="fileInput"
              v-model="selectedFiles"
              multiple
              placeholder="Selecione a planilha Excel..."
              drop-placeholder="Drop file here..."
              size="lg"
              accept=".txt, .csv"
            />
          </b-overlay>
        </b-input-group>
      </b-col>

      <b-col>
        <div
          cols=""
          class="mt-1"
        >
          <b-button
            @click="UploadParams()"
          >
            Enviar Planilha
          </b-button>
        </div>
      </b-col>

    </b-row>
    <validate-logon-keycloak ref="validatelogon" />

  </div>
</template>

<script>
import axios from 'axios'
import * as XLSX from 'xlsx'
import ValidateLogonKeycloak from '@/views/reusable-components/ValidateLogonKeycloak.vue'
import {
  BFormFile, BRow, BCol, BInputGroup, BButton, BOverlay,
} from 'bootstrap-vue'

export default {
  name: 'UploadParams',
  components: {
    BOverlay,
    BButton,
    BInputGroup,
    BFormFile,
    BRow,
    BCol,
    ValidateLogonKeycloak,

  },
  data() {
    return {
      show: false,
      selectedFiles: [],
      filenames: [],
      browseTeste: {
        id: 'browseTeste',
      },
    }
  },

  mounted() { // Mounted has to be used when using a Child and trigger after the import
    this.validateUserLogon()
    this.setUser()
  },

  methods: {
    validateUserLogon() {
      this.$refs.validatelogon.validateLogon()
    },
    setUser() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      this.$parent.$parent.$parent.$refs.adenav.username = userData.fullName
    },
    toast(toaster, append = false, variant, message, title) {
      this.$bvToast.toast(message, {
        title,
        toaster,
        solid: true,
        appendToast: append,
        variant,
      })
    },

    // Função para fazer upload de arquivo e converter para JSON
    async UploadParams() {
      this.show = true // Mostrar o carregamento

      // Função para fazer upload de arquivo
      const uploadAndConvertToJSON = () => new Promise(resolve => {
        const fileInputElement = this.$refs.fileInput.$el.querySelector('input[type="file"]')
        const file = fileInputElement.files[0]
        // Cria um novo objeto FileReader para ler o conteúdo do arquivo
        const reader = new FileReader()

        // Define um evento que será acionado quando a leitura do arquivo estiver concluída
        reader.onload = e => {
          // Neste ponto, o arquivo foi lido com sucesso, e você pode acessar o conteúdo aqui.
          const arrayBuffer = e.target.result
          const data = new Uint8Array(arrayBuffer)
          const excelData = XLSX.read(data, { type: 'array' })

          const result = Object.keys(excelData.Sheets).map(name => ({
            name,
            data: XLSX.utils.sheet_to_json(excelData.Sheets[name]),
          }))

          // Retorne os dados JSON
          resolve(result)
          console.log(result)
        }
        // Lê o conteúdo do arquivo e converte para um ArrayBuffer
        reader.readAsArrayBuffer(file)
      })

      try {
        // Obtenha o CNPJ da empresa selecionado no combo box
        const cnpjSelected = this.$parent.$refs.cboxclient.selected

        if (!cnpjSelected) {
          throw new Error('Selecione o CNPJ')
        }

        // Chama a função que fez upload e converteu o arquivo em JSON
        const jsonData = await uploadAndConvertToJSON()

        // eslint-disable-next-line no-restricted-syntax
        for (const item of jsonData[0].data) {
          const {
            SAFX, COLUNAS, DE, PARA,
          } = item

          // eslint-disable-next-line no-await-in-loop
          const resdePara = await axios.post(`/custom_conversion_rule/fromfile/${cnpjSelected}/${SAFX}/${COLUNAS}/${DE}/${PARA}`)

          console.log(`Resposta para ${SAFX}:`, resdePara)
        }

        this.toast('b-toaster-top-right', true, 'success', 'Regra(s) cadastrada(s) com sucesso', 'Cadastro de regra')

        // Deixa os campos em branco após o salvamento.
        this.selectedFiles = ''

        // Atualizar tabela
        this.$parent.rulesTable()
      } catch (error) {
        const cnpjSelected = this.$parent.$refs.cboxclient.selected
        if (cnpjSelected === null) {
          this.toast('b-toaster-top-right', true, 'danger', 'Selecione um cliente!', 'Erro de usabilidade')
        } else if (error.response) {
          if (error.response.status === 500) {
            this.toast('b-toaster-top-right', true, 'danger', `${error}`)
          } else {
            this.toast('b-toaster-top-right', true, 'warning', `Regra ${this.nomeRegra} já existe`, 'Cadastro de regra')
          }
        } else {
          console.log(error)
        }
      }
      this.show = false // Ocultar o carregamento
    },

  },

}
</script>
